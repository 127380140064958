import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

import "../styles/home.scss";

import logo from '../images/logo.png';
import mockup from '../images/mockup.jpg';
import appStoreBadge from '../images/app-store-badge.svg';
import googlePlayBadge from '../images/google-play-badge.svg';

import { Link } from "gatsby";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="home">
      <div className="logo">
        <img
          src={logo}
          alt="" />
      </div>

      <h1>Ellis - The Gift Giving App</h1>
      <p>Ellis is the easiest way to gift. Get the app and connect your contacts to start sending gifts.</p>

      <img
        className="mockup"
        src={mockup}
        alt="" />

      <div className="stores">
        <a href="https://apps.apple.com/app/id1526306812"><img src={appStoreBadge} alt="Get it on App Store" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.theellisapp.app"><img src={googlePlayBadge} alt="Get it on Google Play" /></a>
      </div>

      <p className="support-link"><Link to="/support/">Get Support</Link></p>
    </div>
  </Layout>
);

export default IndexPage;
